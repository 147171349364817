*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: underline;
  color: steelblue;
}
a:hover {
  text-decoration: underline;
  color: darkslategray;
}
a:visited {
  color: steelblue;
}
a:visited:hover {
  color: darkslategray;
}

.content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.flex-row-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-row-space-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.flex-row-space-evenly {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.flex-row-end {
  display: flex;
  justify-content: end;
  align-items: center;
}
.flex-row-start {
  display: flex;
  justify-content: start;
  align-items: center;
}
.flex-row-start-reverse {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row-reverse;
}
.flex-gap-0_5 {
  gap: 0.5rem;
}
.flex-gap-1 {
  gap: 1rem;
}
.flex-gap-2 {
  gap: 2rem;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.image-button {
  border-radius: 5px;
  background: #efefef;
  display: flex;
  gap: 1rem;
  align-items: center;
  min-width: 50px;
  max-width: 180px;
  padding: 0.5rem;
  box-shadow: 2 4 6 lightgrey;
  border: 1px solid #cacaca;
}
.image-button img {
  width: 20%;
}

.image-button:hover {
  cursor: pointer;
  background: #f4f4f4;
}

.uw-search-title {
  max-height: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
}

.uw-search-logo {
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 1rem;
}
.uw-search-logo img {
  height: 2rem;
}

.data-pair {
  max-width: 10em;
  height: 4rem;
  margin: 0 1em;
}

.data-pair-key {
  color: grey;
  font-size: 0.5em;
}

.data-pair-value p {
  font-size: 0.9em;
}
.data-pair-chip {
  border-radius: 4rem;
  background-color: #4477ad26;
  font-size: 0.9rem;
  padding: 0.5rem;
  color: #4476ad;
}

.key-val {
  border-radius: 5px;
  padding: 0.5em;
  border: 1px solid lightblue;
  max-width: 25em;
  max-height: 8em;
  overflow: hidden;
}

.wrapped-data-boxes {
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
  margin: 1em 0;
}

.itemizedList {
  list-style: disc;
}

.required-doc-box {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 1em;
  margin: 2em 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.required-doc-file {
  border: 2px solid #eaeaea;
  margin: 1rem 0;
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 0.5rem;
}

.no-doc-files {
  border: 2px dashed lightgrey;
  padding: 3rem;
  border-radius: 5px;
  text-align: center;
}

.data-chip {
  display: flex;
  justify-content: space-around;
  border-radius: 15px;
  padding: 5px 10px;
  font-weight: bold;
  max-width: 5rem;
  min-width: 3rem;
}

.data-chip-high {
  background-color: rgba(46, 156, 127, 0.15);
  color: rgb(46, 156, 127);
}

.data-chip-low {
  background-color: rgba(235, 87, 87, 0.15);
  color: rgb(235, 87, 87);
}

.data-chip-okay {
  background-color: rgba(248, 201, 132, 0.15);
  color: rgb(248, 201, 132);
}

.data-chip-no-data {
  background-color: white;
  color: lightgrey;
}

.data-chip-grey {
  background-color: rgb(236, 236, 236);
  color: darkgray;
}

.data-chip-orange-light {
  background-color: rgb(255, 247, 173);
  color: rgb(159, 100, 0);
}

.data-chip-green-light {
  background-color: rgb(205, 250, 205);
  color: rgb(77, 139, 77);
}

.data-chip-brown-light {
  background-color: rgb(233, 228, 219);
  color: rgb(130, 120, 111);
}

.data-chip-purple-light {
  background-color: rgb(230, 226, 245);
  color: rgb(128, 113, 161);
}

.data-chip-blue-light {
  background-color: rgb(226, 247, 248);
  color: rgb(127, 171, 213);
}

.filter-box {
  background-color: #f2f7f9;
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  font-size: 0.9rem;
}

.menu-button {
  min-width: 10rem;
  justify-content: space-between;
}

.wide-menu-1 li {
  min-width: 15rem;
  justify-content: space-between;
}

.side-nav-link {
  border-bottom: 1px solid lightgrey;
  cursor: pointer;
}
.side-nav-link:hover {
  background-color: #eaeaea;
}
.side-nav-active {
  background-color: #f4f4f4;
}

.json-wrapper {
  background-color: white;
  border-radius: 10px;
  min-height: 20rem;
  font-family: monospace;
}

.summary {
  border: '1px solid grey';
}

.summary .summary-row:nth-child(odd) {
  background-color: #eaeaea;
}

.summary-row {
  padding: 0.5em 1em;
  display: flex;
  justify-content: space-between;
}

.loan-analysis-section {
  border: '1px solid blue';
  border-radius: '5px';
}

.highlighted {
  background-color: #faedbc;
}
