.navTab {
  text-decoration: none;
  color: inherit;
  padding: 25px 10px;
  border-bottom: 2px solid white;
}
.navTab.active {
  border-bottom: 2px solid darkgreen;
}
.navTab:hover {
  /* border-bottom: 1px solid grey; */
  cursor: pointer;
}
.navTab:visited {
  color: inherit;
}

.navTab a {
  text-decoration: none;
}
.navTab a:visited {
  color: inherit;
}

.navMenuItem a {
  text-decoration: none;
}
.navMenuItem a:visited {
  color: black;
}
