.navTab {
  color: inherit;
  border-bottom: 2px solid #fff;
  padding: 25px 10px;
  text-decoration: none;
}

.navTab.active {
  border-bottom: 2px solid #006400;
}

.navTab:hover {
  cursor: pointer;
}

.navTab:visited {
  color: inherit;
}

.navTab a {
  text-decoration: none;
}

.navTab a:visited {
  color: inherit;
}

.navMenuItem a {
  text-decoration: none;
}

.navMenuItem a:visited {
  color: #000;
}

*, :before, :after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: #4682b4;
  text-decoration: underline;
}

a:hover {
  color: #2f4f4f;
  text-decoration: underline;
}

a:visited {
  color: #4682b4;
}

a:visited:hover {
  color: #2f4f4f;
}

.content {
  flex-direction: column;
  gap: 1em;
  padding: 1rem;
  display: flex;
}

.flex-row-space-between {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.flex-row-space-around {
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.flex-row-space-evenly {
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}

.flex-row-end {
  justify-content: end;
  align-items: center;
  display: flex;
}

.flex-row-start {
  justify-content: start;
  align-items: center;
  display: flex;
}

.flex-row-start-reverse {
  flex-direction: row-reverse;
  justify-content: start;
  align-items: center;
  display: flex;
}

.flex-gap-0_5 {
  gap: .5rem;
}

.flex-gap-1 {
  gap: 1rem;
}

.flex-gap-2 {
  gap: 2rem;
}

.flex-column {
  flex-direction: column;
  display: flex;
}

.image-button {
  background: #efefef;
  border: 1px solid #cacaca;
  border-radius: 5px;
  align-items: center;
  gap: 1rem;
  min-width: 50px;
  max-width: 180px;
  padding: .5rem;
  display: flex;
  box-shadow: 2px 4px 6px #d3d3d3;
}

.image-button img {
  width: 20%;
}

.image-button:hover {
  cursor: pointer;
  background: #f4f4f4;
}

.uw-search-title {
  justify-content: space-between;
  align-items: center;
  max-height: 1rem;
  margin: 1rem 0;
  display: flex;
}

.uw-search-logo {
  align-items: center;
  gap: 1rem;
  height: 1rem;
  display: flex;
}

.uw-search-logo img {
  height: 2rem;
}

.data-pair {
  max-width: 10em;
  height: 4rem;
  margin: 0 1em;
}

.data-pair-key {
  color: gray;
  font-size: .5em;
}

.data-pair-value p {
  font-size: .9em;
}

.data-pair-chip {
  color: #4476ad;
  background-color: #4477ad26;
  border-radius: 4rem;
  padding: .5rem;
  font-size: .9rem;
}

.key-val {
  border: 1px solid #add8e6;
  border-radius: 5px;
  max-width: 25em;
  max-height: 8em;
  padding: .5em;
  overflow: hidden;
}

.wrapped-data-boxes {
  flex-wrap: wrap;
  gap: 1em;
  margin: 1em 0;
  display: flex;
}

.itemizedList {
  list-style: outside;
}

.required-doc-box {
  border: 1px solid #ddd;
  border-radius: 5px;
  flex-direction: column;
  gap: 1rem;
  margin: 2em 0;
  padding: 1em;
  display: flex;
}

.required-doc-file {
  background-color: #f5f5f5;
  border: 2px solid #eaeaea;
  border-radius: 5px;
  margin: 1rem 0;
  padding: .5rem;
}

.no-doc-files {
  text-align: center;
  border: 2px dashed #d3d3d3;
  border-radius: 5px;
  padding: 3rem;
}

.data-chip {
  border-radius: 15px;
  justify-content: space-around;
  min-width: 3rem;
  max-width: 5rem;
  padding: 5px 10px;
  font-weight: bold;
  display: flex;
}

.data-chip-high {
  color: #2e9c7f;
  background-color: #2e9c7f26;
}

.data-chip-low {
  color: #eb5757;
  background-color: #eb575726;
}

.data-chip-okay {
  color: #f8c984;
  background-color: #f8c98426;
}

.data-chip-no-data {
  color: #d3d3d3;
  background-color: #fff;
}

.data-chip-grey {
  color: #a9a9a9;
  background-color: #ececec;
}

.data-chip-orange-light {
  color: #9f6400;
  background-color: #fff7ad;
}

.data-chip-green-light {
  color: #4d8b4d;
  background-color: #cdfacd;
}

.data-chip-brown-light {
  color: #82786f;
  background-color: #e9e4db;
}

.data-chip-purple-light {
  color: #8071a1;
  background-color: #e6e2f5;
}

.data-chip-blue-light {
  color: #7fabd5;
  background-color: #e2f7f8;
}

.filter-box {
  background-color: #f2f7f9;
  border-radius: 10px;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
  font-size: .9rem;
  display: flex;
}

.menu-button {
  justify-content: space-between;
  min-width: 10rem;
}

.wide-menu-1 li {
  justify-content: space-between;
  min-width: 15rem;
}

.side-nav-link {
  cursor: pointer;
  border-bottom: 1px solid #d3d3d3;
}

.side-nav-link:hover {
  background-color: #eaeaea;
}

.side-nav-active {
  background-color: #f4f4f4;
}

.json-wrapper {
  background-color: #fff;
  border-radius: 10px;
  min-height: 20rem;
  font-family: monospace;
}

.summary {
  border: "1px solid grey";
}

.summary .summary-row:nth-child(odd) {
  background-color: #eaeaea;
}

.summary-row {
  justify-content: space-between;
  padding: .5em 1em;
  display: flex;
}

.loan-analysis-section {
  border: "1px solid blue";
  border-radius: "5px";
}

.highlighted {
  background-color: #faedbc;
}

.uwTool {
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  justify-content: space-between;
  padding: 1rem;
  display: flex;
}

.leaflet-pane, .leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-tile-container, .leaflet-pane > svg, .leaflet-pane > canvas, .leaflet-zoom-box, .leaflet-image-layer, .leaflet-layer {
  position: absolute;
  top: 0;
  left: 0;
}

.leaflet-container {
  overflow: hidden;
}

.leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow {
  user-select: none;
  -webkit-user-drag: none;
}

.leaflet-tile::selection {
  background: none;
}

.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}

.leaflet-safari .leaflet-tile-container {
  -webkit-transform-origin: 0 0;
  width: 1600px;
  height: 1600px;
}

.leaflet-marker-icon, .leaflet-marker-shadow {
  display: block;
}

.leaflet-container .leaflet-overlay-pane svg {
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container .leaflet-marker-pane img, .leaflet-container .leaflet-shadow-pane img, .leaflet-container .leaflet-tile-pane img, .leaflet-container img.leaflet-image-layer, .leaflet-container .leaflet-tile {
  width: auto;
  padding: 0;
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container img.leaflet-tile {
  mix-blend-mode: plus-lighter;
}

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}

.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  touch-action: none;
  touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}

.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}

.leaflet-container a {
  -webkit-tap-highlight-color: #33b5e566;
}

.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}

.leaflet-tile-loaded {
  visibility: inherit;
}

.leaflet-zoom-box {
  box-sizing: border-box;
  z-index: 800;
  width: 0;
  height: 0;
}

.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}

.leaflet-overlay-pane {
  z-index: 400;
}

.leaflet-shadow-pane {
  z-index: 500;
}

.leaflet-marker-pane {
  z-index: 600;
}

.leaflet-tooltip-pane {
  z-index: 650;
}

.leaflet-popup-pane {
  z-index: 700;
}

.leaflet-map-pane canvas {
  z-index: 100;
}

.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}

.lvml {
  behavior: url("#default#VML");
  display: inline-block;
  position: absolute;
}

.leaflet-control {
  z-index: 800;
  pointer-events: visiblePainted;
  pointer-events: auto;
  position: relative;
}

.leaflet-top, .leaflet-bottom {
  z-index: 1000;
  pointer-events: none;
  position: absolute;
}

.leaflet-top {
  top: 0;
}

.leaflet-right {
  right: 0;
}

.leaflet-bottom {
  bottom: 0;
}

.leaflet-left {
  left: 0;
}

.leaflet-control {
  float: left;
  clear: both;
}

.leaflet-right .leaflet-control {
  float: right;
}

.leaflet-top .leaflet-control {
  margin-top: 10px;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}

.leaflet-left .leaflet-control {
  margin-left: 10px;
}

.leaflet-right .leaflet-control {
  margin-right: 10px;
}

.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  transition: opacity .2s linear;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}

.leaflet-zoom-animated {
  transform-origin: 0 0;
}

svg.leaflet-zoom-animated {
  will-change: transform;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform .25s cubic-bezier(0, 0, .25, 1);
  -moz-transition: -moz-transform .25s cubic-bezier(0, 0, .25, 1);
  transition: transform .25s cubic-bezier(0, 0, .25, 1);
}

.leaflet-zoom-anim .leaflet-tile, .leaflet-pan-anim .leaflet-tile {
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

.leaflet-interactive {
  cursor: pointer;
}

.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.leaflet-crosshair, .leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}

.leaflet-popup-pane, .leaflet-control {
  cursor: auto;
}

.leaflet-dragging .leaflet-grab, .leaflet-dragging .leaflet-grab .leaflet-interactive, .leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-image-layer, .leaflet-pane > svg path, .leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive, .leaflet-image-layer.leaflet-interactive, .leaflet-pane > svg path.leaflet-interactive, svg.leaflet-image-layer.leaflet-interactive path {
  pointer-events: visiblePainted;
  pointer-events: auto;
}

.leaflet-container {
  outline-offset: 1px;
  background: #ddd;
}

.leaflet-container a {
  color: #0078a8;
}

.leaflet-zoom-box {
  background: #ffffff80;
  border: 2px dotted #38f;
}

.leaflet-container {
  font-family: Helvetica Neue, Arial, Helvetica, sans-serif;
  font-size: .75rem;
  line-height: 1.5;
}

.leaflet-bar {
  border-radius: 4px;
  box-shadow: 0 1px 5px #000000a6;
}

.leaflet-bar a {
  text-align: center;
  color: #000;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-decoration: none;
  display: block;
}

.leaflet-bar a, .leaflet-control-layers-toggle {
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-bar a:hover, .leaflet-bar a:focus {
  background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
  border-bottom: none;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  color: #bbb;
  background-color: #f4f4f4;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.leaflet-control-zoom-in, .leaflet-control-zoom-out {
  text-indent: 1px;
  font: bold 18px Lucida Console, Monaco, monospace;
}

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}

.leaflet-control-layers {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 5px #0006;
}

.leaflet-control-layers-toggle {
  background-image: url("layers.760a0456.png");
  width: 36px;
  height: 36px;
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url("layers-2x.b7b89169.png");
  background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list, .leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}

.leaflet-control-layers-expanded {
  color: #333;
  background: #fff;
  padding: 6px 10px 6px 6px;
}

.leaflet-control-layers-scrollbar {
  padding-right: 5px;
  overflow: hidden scroll;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.leaflet-control-layers label {
  font-size: 1.08333em;
  display: block;
}

.leaflet-control-layers-separator {
  border-top: 1px solid #ddd;
  height: 0;
  margin: 5px -10px 5px -6px;
}

.leaflet-default-icon-path {
  background-image: url("marker-icon.3f7d3721.png");
}

.leaflet-container .leaflet-control-attribution {
  background: #fffc;
  margin: 0;
}

.leaflet-control-attribution, .leaflet-control-scale-line {
  color: #333;
  padding: 0 5px;
  line-height: 1.4;
}

.leaflet-control-attribution a {
  text-decoration: none;
}

.leaflet-control-attribution a:hover, .leaflet-control-attribution a:focus {
  text-decoration: underline;
}

.leaflet-attribution-flag {
  width: 1em;
  height: .6669em;
  vertical-align: baseline !important;
  display: inline !important;
}

.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}

.leaflet-control-scale-line {
  white-space: nowrap;
  box-sizing: border-box;
  text-shadow: 1px 1px #fff;
  background: #fffc;
  border: 2px solid #777;
  border-top: none;
  padding: 2px 5px 1px;
  line-height: 1.1;
}

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution, .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  box-shadow: none;
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  background-clip: padding-box;
  border: 2px solid #0003;
}

.leaflet-popup {
  text-align: center;
  margin-bottom: 20px;
  position: absolute;
}

.leaflet-popup-content-wrapper {
  text-align: left;
  border-radius: 12px;
  padding: 1px;
}

.leaflet-popup-content {
  min-height: 1px;
  margin: 13px 24px 13px 20px;
  font-size: 1.08333em;
  line-height: 1.3;
}

.leaflet-popup-content p {
  margin: 1.3em 0;
}

.leaflet-popup-tip-container {
  pointer-events: none;
  width: 40px;
  height: 20px;
  margin-top: -1px;
  margin-left: -20px;
  position: absolute;
  left: 50%;
  overflow: hidden;
}

.leaflet-popup-tip {
  pointer-events: auto;
  width: 17px;
  height: 17px;
  margin: -10px auto 0;
  padding: 1px;
  transform: rotate(45deg);
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  color: #333;
  background: #fff;
  box-shadow: 0 3px 14px #0006;
}

.leaflet-container a.leaflet-popup-close-button {
  text-align: center;
  color: #757575;
  background: none;
  border: none;
  width: 24px;
  height: 24px;
  font: 16px / 24px Tahoma, Verdana, sans-serif;
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
}

.leaflet-container a.leaflet-popup-close-button:hover, .leaflet-container a.leaflet-popup-close-button:focus {
  color: #585858;
}

.leaflet-popup-scrolled {
  overflow: auto;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  -ms-zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  width: 24px;
  filter: progid:DXImageTransform. Microsoft. Matrix(M11= .707107, M12= .707107, M21= -.707107, M22= .707107);
  margin: 0 auto;
}

.leaflet-oldie .leaflet-control-zoom, .leaflet-oldie .leaflet-control-layers, .leaflet-oldie .leaflet-popup-content-wrapper, .leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

.leaflet-tooltip {
  color: #222;
  white-space: nowrap;
  user-select: none;
  pointer-events: none;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 6px;
  position: absolute;
  box-shadow: 0 1px 3px #0006;
}

.leaflet-tooltip.leaflet-interactive {
  cursor: pointer;
  pointer-events: auto;
}

.leaflet-tooltip-top:before, .leaflet-tooltip-bottom:before, .leaflet-tooltip-left:before, .leaflet-tooltip-right:before {
  pointer-events: none;
  content: "";
  background: none;
  border: 6px solid #0000;
  position: absolute;
}

.leaflet-tooltip-bottom {
  margin-top: 6px;
}

.leaflet-tooltip-top {
  margin-top: -6px;
}

.leaflet-tooltip-bottom:before, .leaflet-tooltip-top:before {
  margin-left: -6px;
  left: 50%;
}

.leaflet-tooltip-top:before {
  border-top-color: #fff;
  margin-bottom: -12px;
  bottom: 0;
}

.leaflet-tooltip-bottom:before {
  border-bottom-color: #fff;
  margin-top: -12px;
  margin-left: -6px;
  top: 0;
}

.leaflet-tooltip-left {
  margin-left: -6px;
}

.leaflet-tooltip-right {
  margin-left: 6px;
}

.leaflet-tooltip-left:before, .leaflet-tooltip-right:before {
  margin-top: -6px;
  top: 50%;
}

.leaflet-tooltip-left:before {
  border-left-color: #fff;
  margin-right: -12px;
  right: 0;
}

.leaflet-tooltip-right:before {
  border-right-color: #fff;
  margin-left: -12px;
  left: 0;
}

@media print {
  .leaflet-control {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}
/*# sourceMappingURL=index.b39b3bfb.css.map */
